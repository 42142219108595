<template>
  <div class="login authentication" :style="background">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol col="12">
          <CCard class="p-0 m-auto">
            <CCardBody class="p-4">              
              <div v-if="!showResetCodeField">
                <CRow>
                  <CCol col="12" lg="12" class="pt-0 pb-0">
                    <h3 class="mb-0">{{ $t('auth.Verify_your_account') }}</h3>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" lg="12" class="text-left">
                    <div class="d-flex">
                      <!-- <div class="step_number">1.</div> -->
                      <div class="step_content">
                        <div class="mb-2">
                          <span v-html="$t('auth.Enter_6_digit_code')"></span>
                        </div>
                        <div>                          
                          <CodeInput :loading="false" id="userTokenInput" class="user_token_input" v-on:change="onUserTokenChange" v-on:complete="onUserTokenComplete"/>
                          
                          <div v-if="authenticatingUser" class="mt-2">
                            <loadingSpinner mode="inline" :content="$t('auth.Checking_entered_code')"/>
                          </div>

                          <div v-if="!authenticatingUser && errorMessage" class="mt-2">
                            <span>{{errorMessage}}</span>
                          </div>                        
                        </div>
                      </div>
                    </div>               
                  </CCol>
                </CRow>
                <CRow class="notice">
                  <CCol col="12" lg="12" class="pt-0 pb-0 text-center">                                       
                    <div class="d-flex">                      
                      <div class="step_content">                      
                        <span>{{$t('auth.Phone_lost_of_erased')}} <b @click="toggleResetCodeField()" class="pointer">{{$t('Click_here')}}</b> {{$t('auth.to_reset_your_authentication')}}</span>                      
                      </div>
                    </div>
                  </CCol>
                </CRow>                
              </div>
              <div v-else>
                <CRow>
                  <CCol col="12" lg="12" class="pt-0 pb-0">
                    <h3 class="mb-0">{{ $t('auth.Reset_my_authentication') }}</h3>
                  </CCol>
                </CRow>
                <div v-if="!authenticationReset">
                  <CRow>
                    <CCol col="12" lg="12" class="pb-0">              
                      <div class="d-flex">
                        <!-- <div class="step_number">1.</div> -->
                        <div class="step_content">
                          <div class="mb-2">
                            <span v-html="$t('auth.Enter_reset_code')"></span>
                          </div>
                          <div>
                            <CInput id="resetCode" class="m-0" :placeholder="$t('auth.Reset_code')" type="text" v-model="resetCode" tabindex="2"></CInput>
                            
                            <div v-if="verifyingResetCode" class="mt-2">
                              <loadingSpinner mode="inline" :content="$t('auth.Verifying_reset_code')"/>
                            </div>
                            
                            <div v-if="!verifyingResetCode && errorMessage" class="mt-2">
                              <span>{{errorMessage}}</span>
                            </div>

                            <div class="mt-2">
                              <CButton @click="validateResetCode()" color="secondary" :disabled="!resetCode">
                                <span>{{ $t('auth.Verify_reset_code') }}</span>
                              </CButton>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow>
                    <CCol col="12" lg="12" class="pb-0">              
                      <span>{{$t('auth.Authentication_reset_successful')}} <b @click="$router.push('/login')" class="pointer">{{$t('Click_here')}}</b> {{$t('auth.to_return_to_login')}}</span>
                    </CCol>
                  </CRow>                
                </div>
              </div>           
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="logo_placeholder">
      <img src="img/brand/harry_hr_logo_white_23_06_2022.png" style="height: 50px;" />
    </div>      
  </div>  
</template>

<script>
import axios from 'axios';
import CodeInput from "vue-verification-code-input";

import loadingSpinner from '@/components/common/loadingSpinner.vue';

var twofactor = require("node-2fa");

export default {
  name: 'Setup',
  components: {
    CodeInput,
    loadingSpinner
  },  
  data(){
    return {
      authenticationToken: null,
      authenticationData: null,
      userToken: null,
      errorMessage: null,
      authenticatingUser: false,
      showResetCodeField: false,
      verifyingResetCode: false,
      authenticationReset: false,
      resetCode: null,
      background : {
        backgroundImage: "linear-gradient(45deg, rgba(0, 104, 89, 0.9), rgba(48, 146, 139, 0.95)), url('./../img/login/harry_hr_pattern.png')",
        backgroundRepeat: 'repeat',
      }    
    }
  },
  methods: {    
    authenticateUser() {
      // Start the loader
      this.authenticatingUser = true;
      // Reset the errorMessage value
      this.errorMessage = false;
      // Verify the userToken      
      var verified = twofactor.verifyToken(this.authenticationData.auth_secret, this.userToken);

      setTimeout(function() {
        // Stop the loader
        this.authenticatingUser = false;
        // Check the verified value
        if(verified === null) {
          this.errorMessage = this.$t('auth.Code_does_not_match');
        } else if(verified.delta < 0) {
          this.errorMessage = this.$t('auth.Code_entered_too_late');
        } else if(verified.delta > 0) {
          this.errorMessage = this.$t('auth.Code_entered_too_early');
        } else if(verified.delta === 0) {
          let userIdExternal = this.authenticationData.user_id_external;
          let authenticationToken = this.authenticationToken;

          this.$store.dispatch('authenticate', { userIdExternal, authenticationToken })
          .then(res => {
            // Reset the errorMessage value
            this.errorMessage = false;
            // Navigate to dashboard homepage
            this.$router.push('/');
          })
          .catch(err => {
            console.log(err);
          })        
        }
      }.bind(this), 500);
    },
    getUserAuthenticationData() {
      let params = {};
      params.auth_token = this.authenticationToken;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/authentication/user', params)
      .then(res => {
        this.authenticationData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    toggleResetCodeField() {
      // Update the showResetCodeField value
      this.showResetCodeField =  true;
      // Wait 500ms and focus on the userToken field
      setTimeout(function() {
        document.getElementById("resetCode").focus();
      }, 500);      
    },
    onUserTokenChange(data) {
      // Reset the errorMessage value
      this.errorMessage = false;      
      // Update userToken value
      this.userToken = data;
    },
    onUserTokenComplete(data) {
      // Reset the errorMessage value
      this.errorMessage = false;      
      // Update userToken value
      this.userToken = data;
      // Trigger authenticateUser method
      this.authenticateUser();
    },
    validateResetCode() {
      // Start the loader
      this.verifyingResetCode = true;      
      // Reset the errorMessage value
      this.errorMessage = false;
      // Decrypt the entered reset code
      const decryptedResetCode = this.$CryptoJS.AES.decrypt(this.resetCode, this.authenticationData.auth_secret).toString(this.$CryptoJS.enc.Utf8);
      
      setTimeout(function(){
        // Stop the loader
        this.verifyingResetCode = false;
        // Check if the decrypyed reset code is equal to the user email
        if(decryptedResetCode === this.authenticationData.email) {
          let params = {};
          params.auth_token = this.authenticationToken;
          
          axios.post(process.env.VUE_APP_API_URL + '/v1/core/authentication/reset', params)
          .then(res => {
            // Reset the errorMessage value
            this.errorMessage = false;          
            // Update the authenticationReset value
            this.authenticationReset = true;
          })
          .catch(err => {
            console.error(err); 
          });        
        } else {
          this.errorMessage = this.$t('auth.Reset_code_invalid');
        }
      }.bind(this), 500);
    }
  },
  mounted: function() {
    // Check if the authenticationToken is available in localStorage
    if(localStorage.getItem('authenticationToken') !== null) {
      // Set the authenticationToken value
      this.authenticationToken = localStorage.getItem('authenticationToken');
      // Get the authentication data
      this.getUserAuthenticationData();
      // Wait 500ms and focus on the first field of the userToken input
      setTimeout(function() {
        document.querySelector('#userTokenInput .react-code-input > input').focus();        
      }, 500);
    } else {
      // Navigate to login page
      this.$router.push('/login');
    }  
  }
}
</script>
